import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setModal } from "../store/globals/actions";
import '../../scss/modal.scss';
import Button from "./Button";
import {
    setLookBgImage, setLookBgImageFull, setLookBgLoaded, setLookBgLoadedFull,
    setLookStyle3
} from "../store/settings/actions";
import { uploadImage } from "../services/_functions";


class ModalUploadBackground extends Component {

    closeModal = () => {
        this.props.dispatch(setModal(''));
    };

    closeModalOwner = (e) => {
        if (e.target.classList.contains('ModalOwner')) {
            this.props.dispatch(setModal(''));
        }
    };

    uploadImage = (e) => {
        const successFunc = (result) => {
            this.props.dispatch(setLookBgImageFull(result));
            this.props.dispatch(setLookBgLoadedFull(true));
            this.props.dispatch(setLookStyle3(5));
        };

        const failFunc = () => {
            this.closeModal();
        };

        this.props.dispatch(uploadImage(e, successFunc, failFunc));
    };

    clickSelect = () => {
        let file = document.getElementById('bg-modal-file');
        file.click();
    };

    render() {
        return (
            <div className="ModalOwner" onClick={ (e) => this.closeModalOwner(e) }>
                <div className="Modal Modal__download-icon">
                    <div className="Modal__content">
                        <div className="Modal__header">
                            <div className="Modal__header-title">Загрузка фоновой картинки</div>
                            <div className="Modal__close" onClick={ this.closeModal }/>
                        </div>
                        <div className="Modal__body">
                            <p>Можно загрузить картинку в формате .png, .jpg или .svg,<br/>
                                размером не более 5 Мб</p>
                            <Button onClick={ this.clickSelect }>Выбрать файл</Button>
                            <input className="hide-it" type='file' id='bg-modal-file' onChange={ (e) => this.uploadImage(e) } />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
    };
};

export default connect(mapStateToProps)(ModalUploadBackground);