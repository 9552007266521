import axios from 'axios';
import { MAIN_SITE_URL } from "./_globals";


// Загрузить иконку
export const uploadIcon = (img, ext) => {
    return axiosRequest('upload-image', { img, ext });
};


// Добавить отзыв
export const addReview = (vk, review, is_like) => {
    return axiosRequest('add-review', { vk, review, is_like });
};


// Добавить отзыв
export const getVkUserInfo = (id, is_large) => {
    return axiosRequest('get-vk-user-info', { id, is_large });
};

// Получить статус закрытия блока ретаргетинга
export const getRetargeting = (vk) => {
    return axiosRequest('get-retargeting', { vk });
};

// Закрыть блок ретаргетинга
export const setRetargeting = (vk) => {
    return axiosRequest('set-retargeting', { vk });
};

// Сохранить ссылку
export const saveLink = (vk, link) => {
    return axiosRequest('save-link', { vk, link });
};


export const axiosRequest = (url, postData) => {
    const successFunc = (response) => {
        if (response.data.result === 'success') return response.data;
        else return { result: 'fail', error: (response.data.error !== undefined ? response.data.error : 'unknown' ) }
    };

    const failFunc = () => {
        return { result: 'fail', error: 'unknown' }
    };

    let preparedPostData = new FormData();
    Object.keys(postData).map((key) => {
        preparedPostData.append(key, postData[key]);
    });

    // Делаем запрос
    return axios({
        method: 'post',
        url: MAIN_SITE_URL + '/' + url + '/',
        data: preparedPostData,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        }
    }).then(result => successFunc(result)).catch(error => failFunc(error));
};
