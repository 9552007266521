import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../css/block-main.css';
import {setModal} from "../store/globals/actions";
import ModalAbout from "../components/ModalAbout";
import { push } from 'react-router-redux';
import {googleEvent, yandexEvent} from "../services/_functions";
import {resize} from "../services/vk";


class BlockMain extends Component {

    componentDidMount = () => {
        this.props.dispatch(resize(true));
    };

    openModalAbout = () => {
        this.props.dispatch(setModal(<ModalAbout/>))
        googleEvent('What_is_QR', 'What_is_QR');
        yandexEvent('what_is_qr');
    };

    openPage = (page) => {
        this.props.dispatch(push('/' + page));
    };

    openLink = () => {
        this.openPage('link');
        googleEvent('Content type', 'Link', 'Menu');
        yandexEvent('content_type_link_menu');
    };

    openWiFi = () => {
        this.openPage('wifi');
        googleEvent('Content type', 'Wi-Fi', 'Menu');
        yandexEvent('content_type_wi-fi_menu');
    };

    openContact = () => {
        this.openPage('contact');
        googleEvent('Content type', 'Contact', 'Menu');
        yandexEvent('content_type_contact_menu');
    };

    openText = () => {
        this.openPage('text');
        googleEvent('Content type', 'Text', 'Menu');
        yandexEvent('content_type_text_menu');
    };

    openEvent = () => {
        this.openPage('event');
        googleEvent('Content type', 'Event', 'Menu');
        yandexEvent('content_type_event_menu');
    };

    openGeo = () => {
        this.openPage('geo');
        googleEvent('Content type', 'Geo', 'Menu');
        yandexEvent('content_type_geo_menu');
    };

    render() {
        return (
            <div className="Block-main">
                <div className="Block-main_header">
                    <div className="Block-main_header_left">
                        <div className="Block-main_header_left_title">Конструктор QR-кодов</div>
                        <div className="Block-main_header_left_content">Помогите клиентам быстро найти Вашу страницу в интернете. Используйте QR-коды — и делитесь ссылками на Ваши онлайн-ресурсы в офлайне.<br/><br/>Добавьте в QR-код любую информацию: от простой ссылки до настроек Wi-Fi. Чтобы посмотреть материалы, достаточно навести на QR-код камеру ВКонтакте.</div>
                        <div className="Block-main_header_left_link" onClick={ this.openModalAbout }>Подробнее о QR-кодах</div>
                    </div>
                    <div className="Block-main_header_right">
                        <div className="Block-main_header_right_bg"/>
                    </div>
                </div>
                <div className="Block-main_middler">
                    <div className="Menu-item" onClick={ this.openLink }>
                        <div className="Menu-item_icon Menu-item_icon--link"/>
                        <div className="Menu-item_title">Ссылка</div>
                        <div className="Menu-item_desc">Создайте QR-код со ссылкой на Вашу страницу ВКонтакте или любой другой сайт — клиентам не придётся вводить адрес вручную</div>
                    </div>
                    <div className="Menu-item" onClick={ this.openWiFi }>
                        <div className="Menu-item_icon Menu-item_icon--wifi"/>
                        <div className="Menu-item_title">Настройки Wi-Fi</div>
                        <div className="Menu-item_desc">Добавьте в QR-код данные Вашего Wi-Fi, чтобы гости могли подключаться к сети без ввода пароля</div>
                    </div>
                    <div className="Menu-item" onClick={ this.openContact }>
                        <div className="Menu-item_icon Menu-item_icon--contact"/>
                        <div className="Menu-item_title">Визитка</div>
                        <div className="Menu-item_desc">Зашифруйте в QR-коде свои контактные данные: имя, должность, адрес, номер телефона</div>
                    </div>
                    <div className="Menu-item" onClick={ this.openText }>
                        <div className="Menu-item_icon Menu-item_icon--text"/>
                        <div className="Menu-item_title">Текст</div>
                        <div className="Menu-item_desc">Разместите на товаре QR-код с любыми полезными сведениями: например, инструкцией, составом или информацией об акции</div>
                    </div>
                    <div className="Menu-item" onClick={ this.openEvent }>
                        <div className="Menu-item_icon Menu-item_icon--event"/>
                        <div className="Menu-item_title">Событие</div>
                        <div className="Menu-item_desc">Благодаря QR-коду для мероприятия Ваши клиенты смогут добавить напоминание о событии в календарь телефона.</div>
                    </div>
                    <div className="Menu-item" onClick={ this.openGeo }>
                        <div className="Menu-item_icon Menu-item_icon--geo"/>
                        <div className="Menu-item_title">Геолокация</div>
                        <div className="Menu-item_desc">Укажите в QR-коде координаты Вашего заведения или места встречи — клиенты легко смогут проложить туда маршрут.</div>
                    </div>
                </div>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        linkUrl: state.settings.link.url,
        lookType: state.settings.look.selectedType,
    };
};

export default connect(mapStateToProps)(BlockMain);