import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from "../components/Button";
import '../../css/block-qr.css';
import QRCode from "../components/QRCode";
import { svgAsPngUri } from 'save-svg-as-png';
import {googleEvent, urlToGoogleLink, yandexEvent} from "../services/_functions";
import {saveLink} from "../services/axios";
import {setModal} from "../store/globals/actions";
import ModalPrint from "../components/ModalPrint";
import {resize} from "../services/vk";

class BlockQR extends Component {

    savePng = () => {
        const qr = document.getElementById("qr-svg").children[0].children[0].children[0];
        svgAsPngUri(qr, {}).then(uri => {
            setTimeout(() => {
                svgAsPngUri(qr, {scale: 2}).then(uri => {
                    let downloadLink = document.createElement("a");
                    downloadLink.href = uri;
                    downloadLink.download = `qr${((new Date).getTime() % 100000000000).toString().substr(0, 8)}.png`;
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    document.body.removeChild(downloadLink);

                    googleEvent('Download', 'png', urlToGoogleLink(this.props.selectedUrl));
                    yandexEvent('download_png_' + this.props.selectedUrl);
                });
            }, 500);
        });
        if (this.props.selectedUrl === 'link') saveLink(this.props.vkId, this.props.linkUrl);
    };

    saveSvg = () => {
        // get svg element.
        const svg = document.getElementById("qr-svg").children[0].children[0].children[0];

        // get svg source.
        const serializer = new XMLSerializer();
        let source = serializer.serializeToString(svg);

        // add name spaces.
        if(!source.match(/^<svg[^>]+xmlns="http\:\/\/www\.w3\.org\/2000\/svg"/)){
            source = source.replace(/^<svg/, '<svg xmlns="http://www.w3.org/2000/svg"');
        }
        if(!source.match(/^<svg[^>]+"http\:\/\/www\.w3\.org\/1999\/xlink"/)){
            source = source.replace(/^<svg/, '<svg xmlns:xlink="http://www.w3.org/1999/xlink"');
        }

        // add xml declaration
        source = '<?xml version="1.0" standalone="no"?>\r\n' + source;

        // convert svg source to URI data scheme.
        const url = "data:image/svg+xml;charset=utf-8,"+encodeURIComponent(source);

        // set url value to a element's href attribute.
        let downloadLink = document.createElement("a");
        downloadLink.href = url;
        downloadLink.download = `qr${((new Date).getTime() % 100000000000).toString().substr(0, 8)}.svg`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

        googleEvent('Download', 'svg', urlToGoogleLink(this.props.selectedUrl));
        yandexEvent('download_svg_' + this.props.selectedUrl);
        if (this.props.selectedUrl === 'link') saveLink(this.props.vkId, this.props.linkUrl);
    };

    clickPrint = () => {
        this.props.dispatch(setModal(<ModalPrint/>));

        googleEvent('Print', 'Print');
        yandexEvent('print');
    };

    setHeight = () => {
        const height = document.querySelector("#root .Modal") !== null ? document.querySelector("#root .Modal").getBoundingClientRect().height + 120: 0;
        this.props.dispatch(resize(false, height));
    };

    render() {
        return (
            <div className="White-block QR-block">
                <div className="QR-block__qr" id="qr-svg">
                    <QRCode ref={el => (this.componentToPrintRef = el)} />
                </div>
                <div className={ "QR-error" + (this.props.isTooLongQR ? ' QR-error--show' : '') }>QR-код слишком большой и может плохо читаться. Рекомендуем размещать его на носителях большого размера или уменьшить количество символов.</div>
                <div className="QR-block__buttons">
                    <Button className="QR-block__buttons-b1" onClick={ this.savePng }>Скачать PNG</Button>
                    <Button className="QR-block__buttons-b2" onClick={ this.saveSvg }>Скачать SVG</Button>
                    <Button className="QR-block__buttons-b3" color='blank' onClick={this.clickPrint}>Где я могу распечатать?</Button>
                </div>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        isTooLongQR: state.settings.isTooLongQR,
        selectedUrl: state.globals.selectedUrl,
        linkUrl: state.settings.link.url,
        vkId: state.vk.vkUser.id,
    };
};

export default connect(mapStateToProps)(BlockQR);
