import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setModal } from "../store/globals/actions";
import '../../scss/modal.scss';
import {googleEvent, yandexEvent} from "../services/_functions";


class ModalAbout extends Component {

    closeModal = () => {
        this.props.dispatch(setModal(''));
    };

    closeModalOwner = (e) => {
        if (e.target.classList.contains('ModalOwner')) {
            this.props.dispatch(setModal(''));
        }
    };

    render() {
        return (
            <div className="ModalOwner" onClick={ (e) => this.closeModalOwner(e) }>
                <div className="Modal Modal__about">
                    <div className="Modal__header">
                        <div className="Modal__header-title">Подробнее о QR-кодах</div>
                        <div className="Modal__close" onClick={ this.closeModal }/>
                    </div>
                    <div className="Modal__body bordered">
                        <div className="Quote">
                            <div className="Quote__title">Что такое QR-код?</div>
                            <div className="Quote__text">
                                QR-код — по сути, обычный штрих-код, только выглядит иначе. Как и штрих-коды на товарах, QR-код содержит информацию, но какую именно — Вы решаете сами. Это может быть Ваша страница ВКонтакте, сайт, приложение, контактные данные, геолокация, настройки Wi-Fi — всё, что нужно для Ваших задач. Любые материалы, которые Вы добавите в QR-код, Ваши клиенты смогут открыть и посмотреть парой касаний.
                            </div>
                        </div>
                        <div className="Quote">
                            <div className="Quote__title">Как отсканировать QR-код?</div>
                            <div className="Quote__text">
                                Чтобы получить информацию со штрихкода, продавцы используют специальный сканер. Вашим клиентам не нужны особые устройства — достаточно открыть камеру в мобильном приложении ВКонтакте и навести её на QR-код. Приложение распознает его и предложит посмотреть сведения, которые Вы добавили.
                            </div>
                        </div>
                        <div className="Quote">
                            <div className="Quote__title">Как создать QR-код?</div>
                            <div className="Quote__text">
                                Просто введите нужную ссылку или информацию в онлайн-генератор кодов ВКонтакте, выберите один из макетов — и скачайте готовый QR-код в удобном для Вас формате. Генерация QR-кода займёт меньше минуты, а использовать его можно для самых разных задач.
                            </div>
                        </div>
                        <div className="Quote">
                            <div className="Quote__title">Как использовать QR-код?</div>
                            <div className="Quote__text">
                                Применение QR-кода не ограничено никакими рамками — это полезный инструмент и для бизнеса, и для личных целей. Разместить его можно где угодно: на товаре, кассе, открытке, наружной рекламе, слайде в презентации, визитке, футболке и любых других носителях.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
    };
};

export default connect(mapStateToProps)(ModalAbout);
