import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../css/breadcumbs.css';
import { push } from 'react-router-redux';


class Breadcumbs extends Component {

    openMain = () => {
        this.props.dispatch(push(''));
    };

    render() {
        return (
            <div className="Breadcumbs">
                <div className="Breadcumbs_left" onClick={ this.openMain }>Тип кода</div>
                <div className="Breadcumbs_arrow"/>
                <div className="Breadcumbs_right">{ this.props.title }</div>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
    };
};

export default connect(mapStateToProps)(Breadcumbs);