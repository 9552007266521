import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from "../components/Button";
import '../../css/block-retargeting.css';
import {googleEvent, yandexEvent} from "../services/_functions";
import Icon24Cancel from '@vkontakte/icons/dist/24/cancel';
import {setRetargetingClose} from "../store/globals/actions";
import { setRetargeting} from "../services/axios";

class BlockRetargeting extends Component {

    clickHandler = () => {
        googleEvent('Retargeting', 'Read more web');
        yandexEvent('retargeting_read_more_web');
        const linkEl = document.getElementById('link-retargeting');
        linkEl.click();
    };

    closeHandler = () => {
        googleEvent('Retargeting', 'Close');
        yandexEvent('retargeting_close');
        if (process.env.NODE_ENV === 'development') setRetargeting(123);
        else setRetargeting(this.props.vkId.id);
        this.props.dispatch(setRetargetingClose(true));
    };

    render() {
        return (
            <div className="White-block Retargeting">
                <div className="Retargeting-left"><div className="Retargeting-left__icon"/></div>
                <div className="Retargeting-right">
                    <div className="Retargeting-right__title">
                        <div className="Retargeting-right__title-caption">Ретаргетинг по QR-коду</div>
                        <div className="Retargeting-right__title-close"><Icon24Cancel fill="#c4c8cc" onClick={ this.closeHandler }/></div>
                    </div>
                    <div className="Retargeting-right__desc">Собирайте в базу ретаргетинга всех, кто сканировал QR, чтобы продолжить общение онлайн — это «горячая» аудитория для рекламы.</div>
                    <div className="Retargeting-right__btn">
                        <Button onClick={ this.clickHandler }>Подробнее</Button>
                        <a id="link-retargeting" href="http://vk.com/@business-retarg-qr" target="_blank" className="hide-it"/>
                    </div>
                </div>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        vkId: state.vk.vkUser,
    };
};

export default connect(mapStateToProps)(BlockRetargeting);
