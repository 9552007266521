import VKConnectReal from '@vkontakte/vkui-connect';
import VKConnectMock from '@vkontakte/vkui-connect-mock';
import * as VKActions from '../store/vk/actions';
import { store } from '../../index';

// const VKConnect = (process.env.NODE_ENV === 'production') ? VKConnectReal : VKConnectMock;
const VKConnect = VKConnectReal;
const API_VERSION = '5.100';


export const initApp = () => async(dispatch) => {
    return new Promise((resolve, reject) => {
        let handler = async(e) => {
            let vkEvent = e.detail;
            let type = vkEvent.type;
            let data = vkEvent.data;

            switch (type) {

                case 'VKWebAppUpdateConfig':
                    document.body.setAttribute('scheme', data.scheme);
                    dispatch(VKActions.setInsets(data.insets));
                    break;

            }
        };
        VKConnect.subscribe(handler);
        VKConnect.send('VKWebAppInit', { 'no_toolbar': true });
    });
};

export const getUserInfo = () => async (dispatch)  => {
    return new Promise((resolve, reject) => {
        let handler = async(e) => {
            e = e.detail;
            let found = false;

            switch(e.type) {
                case 'VKWebAppGetUserInfoResult':
                    found = true;
                    resolve(dispatch(VKActions.setVkUser(e.data)));
                    return e.data;

                case 'VKWebAppGetUserInfoFailed':
                    found = true;
                    reject(dispatch(VKActions.setVkUserFail()));
                    return '';
            }
            if (found) VKConnect.unsubscribe(handler);
        };
        VKConnect.subscribe(handler);
        VKConnect.send("VKWebAppGetUserInfo", {});
    });
};

export const resize = (isMain = false, forceHeight = undefined) => async (dispatch)  => {
    const rootHeight = document.querySelector('.Block-main') !== null ? document.querySelector('.Block-main').offsetHeight + 62 : 0;
    const containerHeight = document.querySelector('.container') !== null ? document.querySelector('.container').offsetHeight + 62 : 0;
    const modalHeight = document.querySelector("#root .Modal") !== null ? document.querySelector("#root .Modal").offsetHeight + 100 : 0;
    let height = Math.max(rootHeight, modalHeight, containerHeight);

    if (forceHeight !== undefined) height = forceHeight;

    VKConnect.send("VKWebAppResizeWindow", { height });
};
