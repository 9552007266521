import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setTextText } from "../store/settings/actions";
import { UPDATE_TIME } from "../services/_locals";
import {googleEvent, yandexEvent} from "../services/_functions";
import {resize} from "../services/vk";


class BlockText extends Component {

    state = {
        timeout: undefined,
    };

    updateGoogleTimeout;

    componentDidMount = () => {
        this.props.dispatch(resize());
    };

    changeHandler = (e) => {
        const val = e.target.value;
        clearTimeout(this.state.timeout);

        this.setState({ timeout: setTimeout(() => this.props.dispatch(setTextText(val)), UPDATE_TIME) });

        clearTimeout(this.updateGoogleTimeout);
        this.updateGoogleTimeout = setTimeout(() => {
            googleEvent('Edit', 'Text', 'Data');
            yandexEvent('edit_text_data');
        }, 2500);
    };

    render() {
        return (
            <div className="White-block">
                <div className="Form-textarea mt-0">
                    <div className="Form-textarea__name">Текст</div>
                    <textarea maxLength={ 110 } name="" id="" cols="" rows="4" placeholder="Введите текст..." onChange={ this.changeHandler } defaultValue={ this.props.textText }/>
                </div>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        textText: state.settings.text.text,
    };
};

export default connect(mapStateToProps)(BlockText);