import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../css/block-link.css';
import Button from "../components/Button";
import {
    setAdvancedIconSelected, setAdvancedLink, setAdvancedTitle, setAdvancedTopIcon, setAdvancedTopIconLoaded,
    setAdvancedWithImageAndText,
} from "../store/settings/actions";
import { setModal } from "../store/globals/actions";
import ModalUploadIcon from "../components/ModalUploadIcon";
import {googleEvent, uploadImage, wordPad, yandexEvent} from "../services/_functions";
import {resize} from "../services/vk";


class BlockAdvanced extends Component {

    state = {
        isOpen: true,
        charsLeftTitle: 18 - this.props.title.length,
        charsLeftSubTitle: 30 - this.props.link.length,
    };

    updateGoogleTimeoutTitle;
    updateGoogleTimeoutDesc;
    updateSizeTimeout;

    handleOptionChange = (changeEvent) => {
        this.props.dispatch(setAdvancedWithImageAndText(changeEvent.target.checked));
        if (!changeEvent.target.checked) {
            this.props.dispatch(setAdvancedLink(''));
            this.props.dispatch(setAdvancedTitle(''));

            this.setState({ charsLeftTitle: 18 });
            this.setState({ charsLeftSubTitle: 30 });
        }

        if (changeEvent.target.checked) {
            googleEvent('Edit', 'Avatar', 'On');
            yandexEvent('edit_avatar_on');
        } else {
            googleEvent('Edit', 'Avatar', 'Off');
            yandexEvent('edit_avatar_off');
        }
    };

    handleIconOptionChange = (changeEvent) => {
        if (+changeEvent.target.value !== 4) this.props.dispatch(setAdvancedIconSelected(+changeEvent.target.value));

        switch (+changeEvent.target.value) {
            case 1:
                googleEvent('Edit', 'Icon', 'No_Icon');
                yandexEvent('edit_icon_no_icon');
                break;
            case 2:
                googleEvent('Edit', 'Icon', 'Blue_VK');
                yandexEvent('edit_icon_blue_vk');
                break;
            case 3:
                googleEvent('Edit', 'Icon', 'Black_VK');
                yandexEvent('edit_icon_black_vk');
                break;
        }

    };

    openOrCloseBlock = () => {
        if (this.state.isOpen) {
            this.setState({ isOpen: false });
        } else {
            this.setState({ isOpen: true });
        }
    };

    openModal = () => {
        this.props.dispatch(setModal(<ModalUploadIcon/>));
        googleEvent('Edit', 'Icon', 'Upload');
        yandexEvent('edit_icon_upload');
    };

    changeTitleHandler = (e) => {
        this.props.dispatch(setAdvancedTitle(e.target.value));
        this.props.dispatch(setAdvancedWithImageAndText(true));
        this.setState({ charsLeftTitle: 18 - e.target.value.length });

        clearTimeout(this.updateGoogleTimeoutTitle);
        this.updateGoogleTimeoutTitle = setTimeout(() => {
            googleEvent('Edit', 'Page_title', 'New');
            yandexEvent('edit_page_title_new');
        }, 2500);
    };

    changeLinkHandler = (e) => {
        this.props.dispatch(setAdvancedLink(e.target.value));
        this.props.dispatch(setAdvancedWithImageAndText(true));
        this.setState({ charsLeftSubTitle: 30 - e.target.value.length });

        clearTimeout(this.updateGoogleTimeoutDesc);
        this.updateGoogleTimeoutDesc = setTimeout(() => {
            googleEvent('Edit', 'Page_link', 'New');
            yandexEvent('edit_page_link_new');
        }, 2500);
    };

    uploadImage = (e) => {
        const successFunc = (result) => {
            this.props.dispatch(setAdvancedTopIcon(result));
            this.props.dispatch(setAdvancedTopIconLoaded(true));
        };

        const failFunc = () => {};

        this.props.dispatch(uploadImage(e, successFunc, failFunc));
    };

    clickSelect = () => {
        let file = document.getElementById('top-icon-file');
        file.value = '';
        if (!/safari/i.test(navigator.userAgent)) {
            file.type = '';
            file.type = 'file';
        }
        file.click();
        googleEvent('Edit', 'Avatar', 'Upload');
        yandexEvent('edit_avatar_upload');
    };

    unloadImage = () => {
        this.props.dispatch(setAdvancedTopIcon(''));
        this.props.dispatch(setAdvancedTopIconLoaded(false));
    };

    render() {

        clearTimeout(this.updateSizeTimeout);
        this.updateSizeTimeout = setTimeout(() => this.props.dispatch(resize()), 100);

        let imgStyle = {};
        if (this.props.topIconUploaded !== '') imgStyle = { backgroundImage: ('url(' + this.props.topIconUploaded + ')'), backgroundSize: 'cover' };

        const img = <div className="Form-add-img">
                        <div className="Form-add-img__name">Изображение</div>
                        <div className="Form-add-img__box">
                            <div className="Form-add-img__img Form-add-img__img--rounded" style={ imgStyle }>
                                { this.props.isTopIconLoaded && <div className="Form-add-img__img_close" onClick={ this.unloadImage }/> }
                            </div>
                            <div className="Form-add-img__info">
                                <div className="Form-add-img__text">Размер изображения не должен превышать 5 Мб</div>
                                <Button color="light" onClick={ this.clickSelect }>Загрузить изображение</Button>
                                <input className="hide-it" type='file' id='top-icon-file' onChange={ (e) => this.uploadImage(e) } />
                            </div>
                        </div>
                    </div>;

        let leftTitle = 'Осталось ' + this.state.charsLeftTitle + wordPad(this.state.charsLeftTitle, ' символ', ' символа', ' символов');
        if  (this.state.charsLeftTitle === 0) leftTitle = 'Достигнута максимальная длина текста';
        let leftSubTitle = 'Осталось ' + this.state.charsLeftSubTitle + wordPad(this.state.charsLeftSubTitle, ' символ', ' символа', ' символов');
        if  (this.state.charsLeftSubTitle === 0) leftSubTitle = 'Достигнута максимальная длина текста';

        const imageAndText =
            <React.Fragment>
                { !(this.props.selectedType === 4 && this.props.bgImage !== '') && img }
                <div className="Form-input">
                    <div className="Form-input__name">Заголовок</div>
                    <input maxLength={ 18 } type="text" placeholder="Введите заголовок..." onChange={ this.changeTitleHandler } value={ this.props.title } />
                    <div className="Form-input__hint">{ leftTitle }</div>
                </div>
                <div className="Form-input">
                    <div className="Form-input__name">Подзаголовок</div>
                    <input maxLength={ 30 } type="text" placeholder="Введите подзаголовок..." onChange={ this.changeLinkHandler } value={ this.props.link }/>
                    <div className="Form-input__hint">{ leftSubTitle }</div>
                </div>
            </React.Fragment>;

        const imageAndTextFull =
            <div className="Form-toggle">
                { this.props.selectedType !== 4 &&
                    <React.Fragment>
                        <input type="checkbox" id="picture-and-text" checked={ this.props.withImageAndText } onChange={ this.handleOptionChange } />
                        <label htmlFor="picture-and-text">Блок с изображением и названием</label>
                    </React.Fragment>
                }
                { (this.props.withImageAndText || this.props.selectedType === 4) && imageAndText }
            </div>;

        return (
            <div className={ "White-block " + (this.state.isOpen ? '' : ' White-block--close') }>
                <div className={ "Accordion" + (this.state.isOpen ? ' Accordion--open' : ' Accordion--close') }>
                    <div className="Accordion__title" onClick={ this.openOrCloseBlock }>Дополнительно</div>
                    <div className="Accordion__content">
                        <div className="Group-form">
                            <div className="Group-form__name">Иконка</div>
                            <div className="Group-form__radio">
                                <div className="Form-radio-icon">
                                    <input
                                        type="radio"
                                        id="radio-icon-1"
                                        name="radio-icon"
                                        value="1"
                                        checked={ this.props.iconSelected === 1 }
                                        onChange={ this.handleIconOptionChange }
                                    />
                                    <label htmlFor="radio-icon-1">
                                        <span className="Form-radio-icon__img Form-radio-icon__none"/>
                                    </label>
                                </div>
                                <div className="Form-radio-icon">
                                    <input
                                        type="radio"
                                        id="radio-icon-2"
                                        name="radio-icon"
                                        value="2"
                                        checked={ this.props.iconSelected === 2 }
                                        onChange={ this.handleIconOptionChange }
                                    />
                                    <label htmlFor="radio-icon-2">
                                        <span className="Form-radio-icon__img Form-radio-icon__vk"/>
                                    </label>
                                </div>
                                <div className="Form-radio-icon">
                                    <input
                                        type="radio"
                                        id="radio-icon-3"
                                        name="radio-icon"
                                        value="3"
                                        checked={ this.props.iconSelected === 3 }
                                        onChange={ this.handleIconOptionChange }
                                    />
                                    <label htmlFor="radio-icon-3">
                                        <span className="Form-radio-icon__img Form-radio-icon__vk-black"/>
                                    </label>
                                </div>
                                <div className="Form-radio-icon Form-radio-icon__dash">
                                    <div className="Form-radio-icon_div-upload-icon" onClick={ this.openModal }/>
                                </div>
                            </div>
                        </div>
                        { this.props.selectedType !== 1 && imageAndTextFull }
                    </div>
                </div>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        selectedType: state.settings.look.selectedType,
        withImageAndText: state.settings.advanced.withImageAndText,
        iconSelected: state.settings.advanced.iconSelected,
        title: state.settings.advanced.title,
        link: state.settings.advanced.link,
        topIconUploaded: state.settings.advanced.topIconUploaded,
        bgImage: state.settings.look.bgImage,
        isTopIconLoaded: state.settings.advanced.isTopIconLoaded,
    };
};

export default connect(mapStateToProps)(BlockAdvanced);