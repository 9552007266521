import * as types from "./actionTypes";
import * as React from "react";
import * as VK from '@vkontakte/vkui';

const initialState = {
    page: '',
    modal: '',
    colorModal: '',
    link: '',
    selectedUrl: '',
    isShowReviewResult: false,
    isRetargetingClose: true,
};

export const globals = (state = initialState, action) => {

    switch (action.type) {
        case types.SET_PAGE:
            return {
                ...state,
                page: action.page,
            };

        case types.SET_MODAL:
            return {
                ...state,
                modal: action.modal,
            };

        case types.SET_COLOR_MODAL:
            return {
                ...state,
                colorModal: action.colorModal,
            };

        case types.SET_SELECTED_URL:
            return {
                ...state,
                selectedUrl: action.selectedUrl,
            };

        case types.SET_SHOW_REVIEW_RESULT:
            return {
                ...state,
                isShowReviewResult: action.isShowReviewResult,
            };

        case types.SET_RETARGETING_CLOSE:
            return {
                ...state,
                isRetargetingClose: action.close,
            };

        default:
            return state;
    }
};
