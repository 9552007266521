import React, { Component } from 'react';
import { connect } from 'react-redux';
// import '../src/css/style.css';
import '../src/scss/style.scss'
import '../src/css/form.css';
import Footer from "./js/components/Footer";
import BlockQR from "./js/blocks/BlockQR";
import BlockAdvanced from "./js/blocks/BlockAdvanced";
import BlockLook from "./js/blocks/BlockLook";
import BlockLink from "./js/blocks/BlockLink";
import BlockWifi from "./js/blocks/BlockWifi";
import BlockContact from "./js/blocks/BlockContact";
import BlockText from "./js/blocks/BlockText";
import BlockEvent from "./js/blocks/BlockEvent";
import BlockGeo from "./js/blocks/BlockGeo";
import {setModal, setRetargetingClose, setSelectedUrl} from "./js/store/globals/actions";
import ReactGA from 'react-ga';
import BlockMain from "./js/blocks/BlockMain";
import Breadcumbs from "./js/components/Breadcumbs";
import {getUserInfo, initApp, resize} from "./js/services/vk";
import {getRetargeting} from "./js/services/axios";
import BlockRetargeting from "./js/blocks/BlockRetargeting";


class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isConnectionLost: false,
        }
    }

    componentWillMount = async() => {
        this.props.dispatch(initApp());
        await this.props.dispatch(getUserInfo());

        let isRetargetingCloseResult;
        if (process.env.NODE_ENV === 'development') isRetargetingCloseResult = await getRetargeting(123);
        else isRetargetingCloseResult = await getRetargeting(this.props.vkId.id);
        if (isRetargetingCloseResult.result === 'success' && !isRetargetingCloseResult.isClose) {
            this.props.dispatch(setRetargetingClose(false));
        }
    };

    componentDidMount = () => {
        this.props.dispatch(resize());

        window.addEventListener("offline", this.offlineHandler);
        window.addEventListener("online", this.offlineHandler);
        window.onpopstate = this.onBackOrForwardButtonEvent;
    };

    componentWillUnmount() {
        window.removeEventListener("offline", this.offlineHandler);
        window.removeEventListener("online", this.offlineHandler);
        window.removeEventListener('onpopstate', this.onBackOrForwardButtonEvent);
    }

    onBackOrForwardButtonEvent = () => {
        if (this.props.modal !== null && this.props.modal !== '') {
            this.props.dispatch(setModal(''));
        }
    };

    offlineHandler = (event) => {
        if (event.type === 'offline') {
            this.setState({ isConnectionLost: true });
        } else  {
            this.setState({ isConnectionLost: false });
        }
    }

    render() {
        let blocks = '';
        let selected = '';
        let isMainMenu = false;
        let breadcumb = '';

        switch (this.props.pageId) {
            case 'link':
                blocks = <BlockLink/>;
                selected = this.props.pageId;
                breadcumb = 'Ссылка';
                document.title = 'Генератор QR кодов – зашифровка ссылки онлайн';
                break;

            case 'wifi':
                blocks = <BlockWifi/>;
                selected = this.props.pageId;
                breadcumb = 'Настройки Wi-Fi';
                document.title = 'Создать QR-код для подключения к Wi-Fi';
                break;

            case 'contact':
                blocks = <BlockContact/>;
                selected = this.props.pageId;
                breadcumb = 'Визитка';
                document.title = 'Создать визитку с QR-кодом – онлайн генератор';
                break;

            case 'text':
                blocks = <BlockText/>;
                selected = this.props.pageId;
                breadcumb = 'Текст';
                document.title = 'Создать QR-код текста – онлайн зашифровка';
                break;

            case 'event':
                blocks = <BlockEvent/>;
                selected = this.props.pageId;
                breadcumb = 'Событие';
                document.title = 'Создать QR-код запланированного события или календаря онлайн';
                break;

            case 'geo':
                blocks = <BlockGeo/>;
                selected = this.props.pageId;
                breadcumb = 'Геолокация';
                document.title = 'Создать QR-код геолокации онлайн';
                break;

            default:
                blocks = <BlockMain/>;
                selected = '';
                isMainMenu = true;
                document.title = 'Генератор QR кодов – зашифровка ссылки онлайн';
                break;
        }

        ReactGA.pageview(`/${selected}`);
        this.props.dispatch(setSelectedUrl(selected));

        const defaultLayout =
            <React.Fragment>
                <div className="container">
                    <div className="Parts">
                        <div className="Parts__left">
                            <Breadcumbs title={ breadcumb }/>
                            { blocks }
                            <BlockLook/>
                            <BlockAdvanced/>
                            { !this.props.isRetargetingClose && <BlockRetargeting/> }
                        </div>
                        <div className="Parts__right"><BlockQR/></div>
                    </div>
                </div>
            </React.Fragment>;

        return (
            <React.Fragment>
                { !isMainMenu && defaultLayout }
                { isMainMenu && blocks }
                <Footer/>
                { this.props.modal }
                { this.props.colorModal }

                {this.state.isConnectionLost === true && (
                    <div className="toast">
                        <div className="toast__inner">
                            Интернет-соединение потеряно, перезагрузите страницу
                        </div>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        modal: state.globals.modal,
        colorModal: state.globals.colorModal,
        smallHeader: state.globals.smallHeader,
        isRetargetingClose: state.globals.isRetargetingClose,
        vkId: state.vk.vkUser,
    };
}

export default connect(mapStateToProps)(App);
