import React, { Component } from 'react';
import { connect } from 'react-redux';
import QRWidget from '@vkontakte/vk-qr-widget-component';
import {hexToRgb, lead} from "../services/_functions";
import {setIsTooLongQR} from "../store/settings/actions";
import { VK_COLOR } from "../services/_locals";


class QRCode extends Component {

    render() {
        // Кнопка снизу
        let lookText = this.props.lookText !== '' && this.props.lookType !== 1 && this.props.lookType !== 4 ? this.props.lookText : false;

        // Логотип ВК
        let showLogo = true;
        let logoColor = VK_COLOR;
        let iconImg = false;
        if (this.props.iconSelected === 1) showLogo = false;
        if (this.props.iconSelected === 3) logoColor = '#000000';
        if (this.props.iconSelected === 4) iconImg = this.props.iconUploaded;

        // Цвет кода
        let qrColor = '#000000';
        let textColor = VK_COLOR;
        let bgColor = '#ffffff';
        let realTextColor = '#ffffff';

        // Выбран только код
        if (this.props.lookType === 1) {
            bgColor = '#ffffff';
            switch (this.props.selectedStyle1) {
                // Черный
                case 1:
                    qrColor = '#000000';
                    break;
                // Синий
                case 2:
                    qrColor = VK_COLOR;
                    break;
            }
        }
        // Выбран с рамкой
        if (this.props.lookType === 2) {
            bgColor = '#ffffff';
            qrColor = '#000000';
            switch (this.props.selectedStyle2) {
                // Черный
                case 1:
                    textColor = '#000000';
                    break;
                // Синий
                case 2:
                    textColor = VK_COLOR;
                    break;
            }
        }
        // Выбран с фоном
        if (this.props.lookType === 3) {
            switch (this.props.selectedStyle3) {
                // Черный
                case 1:
                    bgColor = '#000000';
                    textColor = '#333333';
                    break;
                // Синий
                case 2:
                    bgColor = VK_COLOR;
                    textColor = '#3B6CA5';
                    break;
                // Прозрачный
                case 3:
                    bgColor = '#ffffff00';
            }
        }

        // Если  выбран кастомный цвет
        if ((this.props.lookType === 1 && this.props.selectedStyle1 === 3) || (this.props.lookType === 2 && this.props.selectedStyle2 === 3) || (this.props.lookType === 3 && this.props.selectedStyle3 === 4)) {
            qrColor = this.props.colorCode;
            textColor = this.props.colorText;
            if (this.props.lookType === 2) {
                realTextColor = this.props.colorFontText;
            }
            if (this.props.lookType === 3) {
                bgColor = this.props.colorBg;
            }
        }

        // Размер
        let size = 1;
        let isShowBg = false;
        if (this.props.lookType === 3) {
            isShowBg = true;
        }

        // Логин и ссылка
        let title = false;
        let login = false;
        let titleColor = '#000000';
        let loginColor = '#457EC1';
        let avatar = undefined;
        if (this.props.withImageAndText && this.props.lookType !== 1) {
            title = this.props.title === '' ? false : this.props.title;
            login = this.props.link === '' ? false : this.props.link;
            avatar = this.props.topIconUploaded;
            size = 2;
            if (login !== false || this.props.lookText !== '') size = 3;
            if (this.props.lookType === 3) {
                if (this.props.selectedStyle3 === 3) {
                    titleColor = '#000000';
                    loginColor = '#457EC1';
                } else {
                    titleColor = '#ffffff';
                    loginColor = '#ffffffa0';
                }
            }
            if (this.props.lookType === 3 && this.props.selectedStyle3 === 4) {
                let color = hexToRgb(this.props.colorBg).r + hexToRgb(this.props.colorBg).g + hexToRgb(this.props.colorBg).b;
                if (color > 600) {
                    titleColor = '#000000';
                    loginColor = '#000000a0';
                } else {
                    titleColor = '#ffffff';
                    loginColor = '#ffffffa0';
                }
            }
            if (this.props.topIconUploaded !== '') avatar = this.props.topIconUploaded;
        }

        // Фон
        let bgImage = undefined;
        let withBg = undefined;
        if (this.props.lookType === 4 && this.props.bgImage !== '') {
            bgImage = this.props.bgImage;
            withBg = true;
            size = 4;
            isShowBg = true;
            titleColor = '#ffffff';
            loginColor = '#ffffffa0';
            title = this.props.title === '' ? ' ' : this.props.title;
            login = this.props.link === '' ? ' ' : this.props.link;
            if (!this.props.withImageAndText) {
                title = ' ';
                login = ' ';
            }
            avatar = 'empty';
        }
        if (this.props.lookType === 3 && this.props.selectedStyle3 === 5 && this.props.bgImage !== '') {
            bgImage = this.props.bgImageFull;
            withBg = true;
            isShowBg = true;
            textColor = '#00000065';
            realTextColor = '#ffffff';
        }

        // URL
        let url = '';
        switch (this.props.selectedUrl) {

            case 'link':
                url = this.props.linkUrl.trim();
                if (url !== '') {
                    if (url.toLowerCase().indexOf('http://') !== -1) url = 'http://' + url.slice(7);
                    if (url.toLowerCase().indexOf('https://') !== -1) url = 'https://' + url.slice(8);
                    if (url.toLowerCase().indexOf('vk.cc/') !== -1 && url.toLowerCase().indexOf('http://') === -1 && url.toLowerCase().indexOf('https://') === -1) url = 'https://' + url;
                    if (url.toLowerCase().indexOf('http://') === -1 && url.toLowerCase().indexOf('https://')) url = 'http://' + url;
                }
                break;

            case 'wifi':
                const wType = ['', 'T:WPA;', 'T:WEP;', ''];
                if (this.props.wifiName !== '' || this.props.wifiPass !== '')
                    url = `WIFI:${wType[this.props.wifiType]}S:${this.props.wifiName};P:${this.props.wifiPass};;`;
                break;

            case 'contact':
                let contactName = '';
                if (this.props.contactLastName !== '' || this.props.contactMidName !== '' || this.props.contactFirstName !== '') contactName = '\nN:' + [this.props.contactLastName, this.props.contactFirstName, this.props.contactMidName].join(';');
                let contactPosition = '';
                if (this.props.contactPosition !== '') contactPosition = '\nTITLE:' + this.props.contactPosition;
                let contactCompany = '';
                if (this.props.contactCompany !== '') contactCompany = '\nORG:' + this.props.contactCompany;
                let contactPhone = '';
                if (this.props.contactPhone !== '') contactPhone = '\nTEL:' + this.props.contactPhone;
                let contactEmail = '';
                if (this.props.contactEmail !== '') contactEmail = '\nEMAIL:' + this.props.contactEmail;
                let contactUrl = '';
                if (this.props.contactUrl !== '') contactUrl = '\nURL:' + this.props.contactUrl;
                if (contactName !== '' || contactCompany !== '' || contactPosition !== '' || contactPhone !== '' || contactUrl !== '' || contactEmail !== '')
                    url = `BEGIN:VCARD\nVERSION:3.0\n${contactName}${contactCompany}${contactPosition}${contactPhone}${contactUrl}${contactEmail}\nEND:VCARD`;
                break;

            case 'text':
                url = this.props.textText;
                break;

            case 'event':
                const eventTitle = '\nSUMMARY:' + (this.props.eventTitle !== '' ? this.props.eventTitle : 'Без названия');
                const eventDescription = '\nURL:' + this.props.eventDescription;

                const moment = require('moment');

                let _date = this.props.eventStartDate;
                let _hours = this.props.eventStartTimeHours;
                let _minutes = this.props.eventStartTimeMinutes;

                if (_date === '') {
                    _date = new Date();
                }
                if (_hours === '') {
                    _hours = 0;
                }
                if (_minutes === '') {
                    _minutes = 0;
                }
                const momentDateStart = moment(_date).add(+lead(_hours) * 60 + (+lead(_minutes)) - moment(_date).utcOffset(), 'minutes');
                const dateStart = momentDateStart.format('YYYYMMDD');
                const hoursStart = momentDateStart.format('HH');
                const minutesStart = momentDateStart.format('mm');
                let eventStartDateTime = `\nDTSTART:${dateStart}T${hoursStart}${minutesStart}00Z`;
                if (_date === '') eventStartDateTime = '';

                const _tomorrow = _date;
                _date = this.props.eventEndDate;
                _hours = this.props.eventEndTimeHours;
                _minutes = this.props.eventEndTimeMinutes;

                if (_date === '') {
                    _date = new Date();
                    _date.setDate(_tomorrow.getDate()+1);
                }
                if (_hours === '') {
                    _hours = 0;
                }
                if (_minutes === '') {
                    _minutes = 0;
                }
                const momentDateEnd = moment(_date).add(+lead(_hours) * 60 + (+lead(_minutes)) - moment(_date).utcOffset(), 'minutes');
                const dateEnd = momentDateEnd.format('YYYYMMDD');
                const hoursEnd = momentDateEnd.format('HH');
                const minutesEnd = momentDateEnd.format('mm');
                let eventEndDateTime = `\nDTEND:${dateEnd}T${hoursEnd}${minutesEnd}00Z`;
                if (_date === '') eventEndDateTime = '';

                if (this.props.eventTitle !== '' || this.props.eventDescription !== '' || eventStartDateTime !== ''|| eventEndDateTime !== '')
                    url = `BEGIN:VEVENT${eventTitle}${eventDescription}${eventStartDateTime}${eventEndDateTime}\nEND:VEVENT`;
                break;

            case 'geo':
                if (this.props.geoPositionX !== '' || this.props.geoPositionY !== '')
                    url = `geo:${this.props.geoPositionX},${this.props.geoPositionY}`;
                break;
        }

        if (url.length > 120) this.props.dispatch(setIsTooLongQR(true));
        else this.props.dispatch(setIsTooLongQR(false));

        let ecc = 0;
        if (url.length < 20) ecc = 3;
        else ecc = 2;

        return (
            <QRWidget
                url={ url }
                qrOptions={{
                    isShowLogo: showLogo,               // show logo in center
                    logoColor: logoColor,               // logo color
                    logoData: iconImg,                  // logo data in base64
                    isShowBackground: isShowBg,         // show qr-background
                    backgroundColor: '#ffffff',         // qr-code background color
                    foregroundColor: qrColor,           // qr-code color
                    ecc: ecc,
                }}
                backgroundColor={ bgColor }
                titleForegroundColor={ titleColor }
                loginForegroundColor={ loginColor }
                actionBackgroundColor={ textColor }
                actionForegroundColor={ realTextColor }
                size={ size }
                avatar={ avatar }
                withImageBackground={ withBg }
                backgroundImage={ bgImage }
                labels={{
                    action: lookText,
                    title: title,
                    login: login,
                }}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        selectedUrl:            state.globals.selectedUrl,
        linkUrl:                state.settings.link.url,
        wifiName:               state.settings.wifi.name,
        wifiPass:               state.settings.wifi.pass,
        wifiType:               state.settings.wifi.wifiType,
        contactFirstName:       state.settings.contact.firstName,
        contactMidName:         state.settings.contact.midName,
        contactLastName:        state.settings.contact.lastName,
        contactPosition:        state.settings.contact.position,
        contactCompany:         state.settings.contact.company,
        contactPhone:           state.settings.contact.phone,
        contactEmail:           state.settings.contact.email,
        contactUrl:             state.settings.contact.url,
        textText:               state.settings.text.text,
        eventTitle:             state.settings.event.title,
        eventStartDate:         state.settings.event.startDate,
        eventStartTimeHours:    state.settings.event.startTimeHours,
        eventStartTimeMinutes:  state.settings.event.startTimeMinutes,
        eventEndDate:           state.settings.event.endDate,
        eventEndTimeHours:      state.settings.event.endTimeHours,
        eventEndTimeMinutes:    state.settings.event.endTimeMinutes,
        eventDescription:       state.settings.event.description,
        geoPositionX:           state.settings.geo.position.x,
        geoPositionY:           state.settings.geo.position.y,
        lookType:               state.settings.look.selectedType,
        lookText:               state.settings.look.text,
        iconSelected:           state.settings.advanced.iconSelected,
        selectedStyle1:         state.settings.look.selectedStyle1,
        selectedStyle2:         state.settings.look.selectedStyle2,
        selectedStyle3:         state.settings.look.selectedStyle3,
        colorCode:              state.settings.look.colorPicker.colorCode,
        colorText:              state.settings.look.colorPicker.colorText,
        colorBg:                state.settings.look.colorPicker.colorBg,
        colorFontText:          state.settings.look.colorPicker.colorFontText,
        withImageAndText:       state.settings.advanced.withImageAndText,
        title:                  state.settings.advanced.title,
        link:                   state.settings.advanced.link,
        iconUploaded:           state.settings.advanced.iconUploaded,
        topIconUploaded:        state.settings.advanced.topIconUploaded,
        bgImage:                state.settings.look.bgImage,
        bgImageFull:            state.settings.look.bgImageFull,
    };
};

export default connect(mapStateToProps)(QRCode);
