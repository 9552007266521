import React, { Component } from 'react';
import { connect } from 'react-redux';
import InputSelect from "../components/InputSelect";
import {googleEvent, uploadImage, wordPad, yandexEvent} from "../services/_functions";
import {
    setAdvancedIconSelected,
    setLookBgImage, setLookBgLoaded, setLookCounterVisibility,
    setLookCPIsBorder, setLookCPIsText, setLookSelectedType, setLookStyle1, setLookStyle2,
    setLookStyle3,
    setLookText
} from "../store/settings/actions";
import Button from "../components/Button";
import 'rc-color-picker/assets/index.css';
import '../../css/color-picker.css';
import '../../css/block-link.css';
import ColorModalMultiple from "../components/ColorModalMultiple";
import { setColorModal, setModal } from "../store/globals/actions";
import ModalUploadBackground from "../components/ModalUploadBackground";
import {resize} from "../services/vk";

class BlockLook extends Component {

    state = {
        isOpen: true,
        charsLeft: 30,
    };

    updateSizeTimeout;

    recalcLeft = (left, text, isTextChangedByUser = true) => {
        this.setState({ charsLeft: left });
        this.props.dispatch(setLookText(text, isTextChangedByUser));
        if (text !== '') this.props.dispatch(setLookCPIsText(true));
        else this.props.dispatch(setLookCPIsText(false));
    };

    handleOptionChange = (changeEvent) => {
        this.props.dispatch(setLookSelectedType(+changeEvent.target.value));
        // Тип 3 включаем бордер
        if (+changeEvent.target.value === 3) this.props.dispatch(setLookCPIsBorder(true));
        else this.props.dispatch(setLookCPIsBorder(false));
        // Тип 2 или 3 включаем по умолчанию рамку "откройте камерой ВК"
        if ((+changeEvent.target.value === 2 || +changeEvent.target.value === 3) && this.props.lookText === '') {
            if (!this.props.isTextChangedByUser) this.recalcLeft(11, 'Откройте камерой VK', false, true);
        }

        // Если юзер не выбирал кастомную иконку, то выбираем иконку в зависимости от стиля
        if (!this.props.isIconSelectedByUser) {
            if (+changeEvent.target.value === 1) {
                if (this.props.selectedStyle1 === 1) this.props.dispatch(setAdvancedIconSelected(3, false));
                if (this.props.selectedStyle1 === 2) this.props.dispatch(setAdvancedIconSelected(2, false));
            }
            if (+changeEvent.target.value === 2) {
                if (this.props.selectedStyle2 === 1) this.props.dispatch(setAdvancedIconSelected(3, false));
                if (this.props.selectedStyle2 === 2) this.props.dispatch(setAdvancedIconSelected(2, false));
            }
            if (+changeEvent.target.value === 3) {
                if (this.props.selectedStyle3 === 1) this.props.dispatch(setAdvancedIconSelected(3, false));
                if (this.props.selectedStyle3 === 2) this.props.dispatch(setAdvancedIconSelected(2, false));
            }
        }

        switch (+changeEvent.target.value) {
            case 1:
                googleEvent('Edit', 'Type', 'Plain_code');
                yandexEvent('edit_type_plain_code');
                break;

            case 2:
                googleEvent('Edit', 'Type', 'With_frame');
                yandexEvent('edit_type_with_frame');
                break;

            case 3:
                googleEvent('Edit', 'Type', 'With_background');
                yandexEvent('edit_type_with_background');
                break;

            case 4:
                googleEvent('Edit', 'Type', 'With_picture');
                yandexEvent('edit_type_with_picture');
                break;
        }
    };

    handleOptionChangeStyle1 = (changeEvent) => {
        this.props.dispatch(setLookStyle1(+changeEvent.target.value));
        switch (+changeEvent.target.value) {
            case 1:
                this.props.dispatch(setAdvancedIconSelected(3, false));
                googleEvent('Edit', 'Color', 'Black');
                yandexEvent('edit_color_black');
                break;

            case 2:
                this.props.dispatch(setAdvancedIconSelected(2, false));
                googleEvent('Edit', 'Color', 'Blue');
                yandexEvent('edit_color_blue');
                break;

            case 3:
                googleEvent('Edit', 'Color', 'Open_color_picker');
                yandexEvent('edit_color_open_color_picker');
                break;
        }
    };

    handleOptionChangeStyle2 = (changeEvent) => {
        this.props.dispatch(setLookStyle2(+changeEvent.target.value));

        switch (+changeEvent.target.value) {
            case 1:
                this.props.dispatch(setAdvancedIconSelected(3, false));
                googleEvent('Edit', 'Color', 'Black');
                yandexEvent('edit_color_black');
                break;

            case 2:
                this.props.dispatch(setAdvancedIconSelected(2, false));
                googleEvent('Edit', 'Color', 'Blue');
                yandexEvent('edit_color_blue');
                break;

            case 3:
                googleEvent('Edit', 'Color', 'Open_color_picker');
                yandexEvent('edit_color_open_color_picker');
                break;
        }
    };

    handleOptionChangeStyle3 = (changeEvent) => {
        this.props.dispatch(setLookStyle3(+changeEvent.target.value));
        switch (+changeEvent.target.value) {
            case 1:
                this.props.dispatch(setAdvancedIconSelected(3, false));
                googleEvent('Edit', 'Color', 'Black');
                yandexEvent('edit_color_black');
                break;

            case 2:
                this.props.dispatch(setAdvancedIconSelected(2, false));
                googleEvent('Edit', 'Color', 'Blue');
                yandexEvent('edit_color_blue');
                break;

            case 3:
                googleEvent('Edit', 'Color', 'Transparent');
                yandexEvent('edit_color_transparent');
                break;

            case 4:
                googleEvent('Edit', 'Color', 'Open_color_picker');
                yandexEvent('edit_color_open_color_picker');
                break;
        }
    };

    openColorMultiple = (id) => {
        const pos = document.getElementById(id).getBoundingClientRect();
        const leftOffset = window.scrollX;
        const topOffset = window.scrollY;
        this.props.dispatch(setColorModal(<ColorModalMultiple posTop={ pos.top + topOffset } posLeft={ pos.left + leftOffset }/>));
    };

    openOrCloseBlock = () => {
        if (this.state.isOpen) {
            this.setState({ isOpen: false });
        } else {
            this.setState({ isOpen: true });
        }
    };

    uploadImage = (e) => {
        const successFunc = (result) => {
            this.props.dispatch(setLookBgImage(result));
            this.props.dispatch(setLookBgLoaded(true));
        };

        const failFunc = () => {};

        this.props.dispatch(uploadImage(e, successFunc, failFunc));
    };

    clickSelect = () => {
        let file = document.getElementById('bg-image-file');
        file.value = '';
        if (!/safari/i.test(navigator.userAgent)) {
            file.type = '';
            file.type = 'file';
        }
        file.click();
    };

    unloadImage = () => {
        this.props.dispatch(setLookBgImage(''));
        this.props.dispatch(setLookBgLoaded(false));
    };

    openUploadBgModal = () => {
        this.props.dispatch(setModal(<ModalUploadBackground/>));
        googleEvent('Edit', 'Color', 'Picture');
        yandexEvent('edit_color_picture');
    };

    componentDidMount() {
        this.setState({ charsLeft: 30 - this.props.lookText.length });
    }

    render() {
        let leftTitle = 'Осталось ' + this.state.charsLeft + wordPad(this.state.charsLeft, ' символ', ' символа', ' символов');
        if  (this.state.charsLeft === 0) leftTitle = 'Достигнута максимальная длина текста';

        let textBlock =
            <div className="Form-input">
                <div className="Form-input__name">Текст</div>
                <InputSelect
                    placeholder="Выберите предложенный вариант или введите свой..."
                    options={[
                        'Сканируйте камерой VK',
                        'Откройте камерой VK',
                        'Узнайте с помощью камеры VK',
                        'Подписаться',
                        'Присоединиться',
                    ]}
                    maxlen={ 30 }
                    changeFunc={ this.recalcLeft }
                    value={ this.props.lookText }
                    withClear={ true }
                />
                { this.props.isCounterVisible && <div className="Form-input__hint">{ leftTitle }</div> }
            </div>;

        let colorBlocks1 =
            <React.Fragment>
                <div className="Group-form__name">Цвет кода</div>
                <div className="Group-form__radio">
                    <div className="Form-radio-color color-black">
                        <input
                            type="radio"
                            id="radio-style-1"
                            name="radio-box-style1"
                            checked={ this.props.selectedStyle1 === 1 }
                            value="1"
                            onChange={ this.handleOptionChangeStyle1 }
                        />
                        <label htmlFor="radio-style-1"/>
                    </div>
                    <div className="Form-radio-color color-blue">
                        <input
                            type="radio"
                            id="radio-style-2"
                            name="radio-box-style1"
                            checked={ this.props.selectedStyle1 === 2 }
                            value="2"
                            onChange={ this.handleOptionChangeStyle1 }
                        />
                        <label htmlFor="radio-style-2"/>
                    </div>
                    <div className="Form-radio-color color-gradient">
                        <input
                            type="radio"
                            id="radio-style-3"
                            name="radio-box-style1"
                            checked={ this.props.selectedStyle1 === 3 }
                            value="3"
                            onChange={ this.handleOptionChangeStyle1 }
                            onClick={ () => this.openColorMultiple('radio-style-3') }
                        />
                        <label htmlFor="radio-style-3">
                            <span className="Form-radio-color__option"/>
                        </label>
                    </div>
                </div>
            </React.Fragment>;

        let colorBlocks2 =
            <React.Fragment>
                <div className="Group-form__name">Стиль</div>
                <div className="Group-form__radio">
                    <div className="Form-radio-color color-black">
                        <input
                            type="radio"
                            id="radio-style-1"
                            name="radio-box-style2"
                            checked={ this.props.selectedStyle2 === 1 }
                            value="1"
                            onChange={ this.handleOptionChangeStyle2 }
                        />
                        <label htmlFor="radio-style-1"/>
                    </div>
                    <div className="Form-radio-color color-blue">
                        <input
                            type="radio"
                            id="radio-style-2"
                            name="radio-box-style2"
                            checked={ this.props.selectedStyle2 === 2 }
                            value="2"
                            onChange={ this.handleOptionChangeStyle2 }
                        />
                        <label htmlFor="radio-style-2"/>
                    </div>
                    <div className="Form-radio-color color-gradient">
                        <input
                            type="radio"
                            id="radio-style-3"
                            name="radio-box-style2"
                            checked={ this.props.selectedStyle2 === 3 }
                            value="3"
                            onChange={ this.handleOptionChangeStyle2 }
                            onClick={ () => this.openColorMultiple('radio-style-3') }
                        />
                        <label htmlFor="radio-style-3">
                            <span className="Form-radio-color__option"/>
                        </label>
                    </div>
                </div>
            </React.Fragment>;

        let colorBlocks3 =
            <React.Fragment>
                <div className="Group-form__name">Стиль</div>
                <div className="Group-form__radio">
                    <div className="Form-radio-color color-black">
                        <input
                            type="radio"
                            id="radio-style-1"
                            name="radio-box-style3"
                            checked={ this.props.selectedStyle3 === 1 }
                            value="1"
                            onChange={ this.handleOptionChangeStyle3 }
                        />
                        <label htmlFor="radio-style-1"/>
                    </div>
                    <div className="Form-radio-color color-blue">
                        <input
                            type="radio"
                            id="radio-style-2"
                            name="radio-box-style3"
                            checked={ this.props.selectedStyle3 === 2 }
                            value="2"
                            onChange={ this.handleOptionChangeStyle3 }
                        />
                        <label htmlFor="radio-style-2"/>
                    </div>
                    <div className="Form-radio-color color-transparent">
                        <input
                            type="radio"
                            id="radio-style-3"
                            name="radio-box-style3"
                            checked={ this.props.selectedStyle3 === 3 }
                            value="3"
                            onChange={ this.handleOptionChangeStyle3 }
                        />
                        <label htmlFor="radio-style-3"/>
                    </div>
                    <div className="Form-radio-color color-gradient">
                        <input
                            type="radio"
                            id="radio-style-4"
                            name="radio-box-style3"
                            checked={ this.props.selectedStyle3 === 4 }
                            value="4"
                            onChange={ this.handleOptionChangeStyle3 }
                            onClick={ () => this.openColorMultiple('radio-style-4') }
                        />
                        <label htmlFor="radio-style-4">
                            <span className="Form-radio-color__option"/>
                        </label>
                    </div>
                    <div className="Form-radio-color">
                        <div className="Form-radio-color_color-photo" onClick={ this.openUploadBgModal }/>
                    </div>
                </div>
            </React.Fragment>;

        let imgStyle = {};
        if (this.props.isBgLoaded) imgStyle = { backgroundImage: ('url(' + this.props.bgImage + ')'), backgroundSize: 'cover' };

        let  addImg =
            <div className="Form-add-img">
                <div className="Form-add-img__name">Изображение</div>
                <div className="Form-add-img__box">
                    <div className="Form-add-img__img" style={ imgStyle }>
                        { this.props.isBgLoaded && <div className="Form-add-img__img_close" onClick={ this.unloadImage }/> }
                    </div>
                    <div className="Form-add-img__info">
                        <div className="Form-add-img__text">Размер изображения не должен превышать 5 Мб</div>
                        <Button color="light" onClick={ this.clickSelect }>Загрузить изображение</Button>
                        <input className="hide-it" type='file' id='bg-image-file' onChange={ (e) => this.uploadImage(e) } />
                    </div>
                </div>
            </div>;

        clearTimeout(this.updateSizeTimeout);
        this.updateSizeTimeout = setTimeout(() => this.props.dispatch(resize()), 100);

        return (
            <div className={ "White-block " + (this.state.isOpen ? '' : ' White-block--close') }>
                <div className={ "Accordion" + (this.state.isOpen ? ' Accordion--open' : ' Accordion--close') }>
                    <div className="Accordion__title" onClick={ this.openOrCloseBlock }>Внешний вид</div>
                    <div className="Accordion__content">
                        <div className="Group-form">
                            <div className="Group-form__name">Тип</div>
                            <div className="Group-form__radio">
                                <div className="Form-radio">
                                    <input type="radio" id="radio-type-1" name="radio-box-type" value="1" defaultChecked={ this.props.selectedType === 1 } onChange={ this.handleOptionChange }/>
                                    <label htmlFor="radio-type-1">Только код</label>
                                </div>
                                <div className="Form-radio">
                                    <input type="radio" id="radio-type-2" name="radio-box-type" value="2" defaultChecked={ this.props.selectedType === 2 } onChange={ this.handleOptionChange }/>
                                    <label htmlFor="radio-type-2">С рамкой</label>
                                </div>
                                <div className="Form-radio">
                                    <input type="radio" id="radio-type-3" name="radio-box-type" value="3" defaultChecked={ this.props.selectedType === 3 } onChange={ this.handleOptionChange }/>
                                    <label htmlFor="radio-type-3">С фоном</label>
                                </div>
                                <div className="Form-radio">
                                    <input type="radio" id="radio-type-4" name="radio-box-type" value="4" defaultChecked={ this.props.selectedType === 4 } onChange={ this.handleOptionChange }/>
                                    <label htmlFor="radio-type-4">Карточка</label>
                                </div>
                            </div>
                        </div>
                        <div className="Group-form">
                            { (this.props.selectedType === 1) && colorBlocks1 }
                            { (this.props.selectedType === 2) && colorBlocks2 }
                            { (this.props.selectedType === 3) && colorBlocks3 }
                            { (this.props.selectedType === 4) && addImg }
                        </div>
                        { (this.props.selectedType === 2 || this.props.selectedType === 3) && textBlock }
                    </div>
                </div>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        lookText: state.settings.look.text,
        selectedType: state.settings.look.selectedType,
        selectedStyle1: state.settings.look.selectedStyle1,
        selectedStyle2: state.settings.look.selectedStyle2,
        selectedStyle3: state.settings.look.selectedStyle3,
        bgImage: state.settings.look.bgImage,
        isBgLoaded: state.settings.look.isBgLoaded,
        isIconSelectedByUser: state.settings.advanced.isIconSelectedByUser,
        isTextChangedByUser: state.settings.look.isTextChangedByUser,
        isCounterVisible: state.settings.look.isCounterVisible,
    };
};

export default connect(mapStateToProps)(BlockLook);