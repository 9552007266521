import React, { Component } from 'react';
import { connect } from 'react-redux';
import {setModal, setShowReviewResult} from "../store/globals/actions";
import '../../scss/modal.scss';
import Button from "./Button";
import {addReview} from "../services/axios";


class ModalFeedback extends Component {

    state = {
        review: '',
        isLike: 1,
        reviewError: false,
    };

    closeModal = () => {
        this.props.dispatch(setModal(''));
    };

    closeModalOwner = (e) => {
        if (e.target.classList.contains('ModalOwner')) {
            this.props.dispatch(setModal(''));
        }
    };

    sendFeedback = () => {
        if (this.state.review !== '') {
            addReview(this.props.vkId, this.state.review, this.state.isLike);
            this.closeModal();
            this.props.dispatch(setShowReviewResult(true));
            setTimeout(() => this.props.dispatch(setShowReviewResult(false)), 10000);
        } else {
            this.setState({ reviewError: true });
        }
    };

    onChangeReview = (e) => {
        if (e.target.value !== '') this.setState({ review: e.target.value, reviewError: false });
        else this.setState({ review: e.target.value });
    };

    onChangeIsLike = (e) => {
        this.setState({ isLike: e.target.value });
    };

    render() {
        return (
            <div className="ModalOwner" onClick={ (e) => this.closeModalOwner(e) }>
                <div className="Modal Modal__feedback">
                    <div className="Modal__content">
                        <div className="Modal__header">
                            <div className="Modal__header-title">Обратная связь</div>
                            <div className="Modal__close" onClick={ this.closeModal }/>
                        </div>
                        <div className="Modal__body">
                            <div className="Group-form__name">Нравится ли Вам сервис?</div>
                            <div className="Group-form__radio flex-direction-column">
                                <div className="Form-radio">
                                    <input type="radio" id="feedback-1" name="feedback-box" defaultChecked={ this.state.isLike === 1 } value={ 1 } onChange={ this.onChangeIsLike }/>
                                    <label htmlFor="feedback-1">Да</label>
                                </div>
                                <div className="Form-radio">
                                    <input type="radio" id="feedback-2" name="feedback-box" defaultChecked={ this.state.isLike === 0 } value={ 0 } onChange={ this.onChangeIsLike }/>
                                    <label htmlFor="feedback-2">Нет</label>
                                </div>
                            </div>
                            <div className="Form-textarea">
                                <div className="Form-textarea__name">Поделитесь с нами своими мыслями по улучшению сервиса</div>
                                <textarea maxLength={ 1000 } value={ this.state.review } onChange={ this.onChangeReview } rows="4" placeholder="Ваше сообщение…" className={ this.state.reviewError ? 'textarea-error' : '' }/>
                                <div className={ "textarea-desc-error" + (this.state.reviewError ? ' show' : '') }>Введите текст сообщения</div>
                            </div>
                        </div>
                        <div className="Modal__footer">
                            <Button color="light" onClick={ this.closeModal }>Отмена</Button>
                            <Button onClick={ this.sendFeedback }>Отправить</Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        vkId: state.vk.vkUser.id,
    };
};

export default connect(mapStateToProps)(ModalFeedback);